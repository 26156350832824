/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable class-methods-use-this */
// import sourceHelperSwRegister from 'service-worker-loader?publicPath=/!./resource-helper-sw';

function sourceHelperSwRegister(options: RegistrationOptions) {
  return navigator.serviceWorker.register('/resource-helper-sw.js', {
    type: 'module',
    ...options,
  });
}

export class SWActiveError extends Error {}
function untilSwActive(swr: ServiceWorkerRegistration) {
  return new Promise<ServiceWorker>((resolve) => {
    const sw = swr.installing ?? swr.waiting ?? swr.active;
    if (!sw) {
      throw new SWActiveError();
    }
    if (sw.state === 'activated') {
      resolve(sw);
    }
    sw.addEventListener('statechange', () => {
      if (sw.state === 'activated') {
        resolve(sw);
      }
    });
  });
}

class ResourceHelper {
  async activate() {
    const swr = await sourceHelperSwRegister({ scope: '/' });
    await untilSwActive(swr);
  }
}

export default new ResourceHelper();
