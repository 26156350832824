import './uno.css';
// import 'uno.css';
// if (process.env.VUE_APP_BUILD_TOOL === 'vite') {
//   require('virtual:uno.css');
// } else {
//   require('uno.css');
// }
import './process.env';
import '@/utils/document-domain';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { parseUrl, stringify } from 'query-string';
import { first } from '@4dst-saas/public-utils';
// import app from './app';
// import { ready } from './utils/x-storage';
import resourceHelper from './resource-helper';
import FimHostParams from './utils/host-params/fim';
import filterConsole from '@4dst-saas/public-utils/dist/filter-console';
import CorsWorker from './utils/cros-worker';

filterConsole(
  [
    'You are running Vue in development mode.',
    'TIM',
    '三角面数',
    '贴图查找失败',
    '多线程加载',
    'PixiJS',
    // '[Vue warn]: Write operation failed: computed value is readonly',
  ],
  {
    console,
  }
);

globalThis.Worker = CorsWorker;

const rRedirectToOpt = /\/\/opt(?:\.sit|uat|-en)?\.4dshoetech\.(?:local|com)\//;
function getRedirectUrl(url: string) {
  const hostParams = new FimHostParams(url.split('?')[0]);
  if (hostParams.fld === '4dshoetech') {
    const { query } = parseUrl(url);
    const newHostParams = hostParams.clone({
      0: hostParams.region === 'eu' ? 'ai' : 'co',
      1: 'revofim',
      4: '',
    });
    let redirect = first(query.redirect);
    if (redirect) {
      // 如果重定向到运营平台，那就算了...
      if (redirect.search(rRedirectToOpt) !== -1) {
        return url;
      }
      redirect = getRedirectUrl(redirect);
    }
    const queryString = stringify(query);
    return `https://${newHostParams}${queryString ? `?${queryString}` : ''}`;
  }
  return url;
}

async function main() {
  // ready();
  resourceHelper.activate();
  const app = (await import('./app')).default;
  // debugger;
  // const app = (await import('./app')).default;
  app.$mount('#app');
  // eslint-disable-next-line no-constant-condition
}

const { href } = window.location;
const redirectUrl = getRedirectUrl(href);
if (redirectUrl !== href) {
  window.location.replace(redirectUrl);
} else {
  main();
}
