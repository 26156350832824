/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */
import tagLog from '@4dst-saas/public-utils/dist/tag-log';

// type ConsoleTag = Parameters<typeof tagLog>[0];
// class TaggedConsole {
//   #tag: ConsoleTag;
//   log(...args: any[]) {
//     console.log(...tagLog(this.#tag, ...args));
//   }
//   warn(...args: any[]) {
//     console.warn(...tagLog(this.#tag, ...args));
//   }
//   constructor(tag: ConsoleTag) {
//     this.#tag = tag;
//   }
// }
// Symbol.hasInstance

const presetTags = {
  info: {
    content: 'INFO',
    style: {
      backgroundColor: '#2a53cd',
      color: 'white',
    },
  },
  warn: {
    content: 'WARN',
    style: {
      backgroundColor: '#f5bd00',
      color: '#202124',
    },
  },
  error: {
    content: 'ERROR',
    style: {
      backgroundColor: '#eb3941',
      color: 'white',
    },
  },
};

type PresetTag = keyof typeof presetTags;

export function info(message: unknown, ...params: unknown[]) {
  console.info(...tagLog(presetTags.info, message, ...params));
}

export function warn(message: unknown, ...params: unknown[]) {
  console.warn(...tagLog(presetTags.warn, message, ...params));
}

export function error(message: unknown, ...params: unknown[]) {
  console.error(...tagLog(presetTags.error, message, ...params));
}

export function logAs(as: PresetTag, message: unknown, ...params: unknown[]) {
  console.log(...tagLog(presetTags[as], message, ...params));
}

export function traceAs(as: PresetTag, message: unknown, ...params: unknown[]) {
  console.trace(...tagLog(presetTags[as], message, ...params));
}

export function log(message: unknown, ...params: unknown[]) {
  return console.log(message, ...params);
}

export function group(as: PresetTag, message: unknown, ...params: unknown[]) {
  console.group(...tagLog(presetTags[as], message, ...params));
}

export function groupCollapsed(as: PresetTag, message: unknown, ...params: unknown[]) {
  console.groupCollapsed(...tagLog(presetTags[as], message, ...params));
}

export function groupEnd() {
  return console.groupEnd();
}

export function table<T extends object>(
  objArr: T[],
  tableHeaders?: { [k in string]: keyof T | ((obj: T) => unknown) } & {
    key?: keyof T | ((obj: T) => unknown);
  }
) {
  const { key, ...restHeaders } = tableHeaders ?? {};
  return console.table(
    Object.fromEntries(
      objArr.map((obj, idx) => {
        return [
          // eslint-disable-next-line no-nested-ternary
          key ? (typeof key === 'function' ? key(obj) : obj[key]) : idx,
          restHeaders
            ? Object.fromEntries(
                Object.entries(restHeaders).map(([targetKey, fnOrKey]) => [targetKey, typeof fnOrKey === 'function' ? fnOrKey(obj) : obj[fnOrKey]])
              )
            : obj,
        ];
      })
    )
  );
}

export const echo = {
  info,
  error,
  warn,
  logAs,
  group,
  groupCollapsed,
  groupEnd,
  log,
  table,
};

export default echo;
