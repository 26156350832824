/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-syntax */

import config from '@/config';
import { Class } from 'ts-toolbelt';

export class HostParams {
  static hostEnv2env: { [hostEnv: string]: string } = {
    sit: 'staging',
    uat: 'uat',
    dev: 'development',
    '': 'production',
  };
  static env2HostEnv: { [env: string]: string } = {
    staging: 'sit',
    development: 'dev',
    production: '',
    poc: 'poc',
    uat: 'uat',
  };
  static mainModule: string = '';
  static cooperators: string[] = [];
  static modules: string[] = [];
  // static modules: { [moduleName: string]: boolean } = {
  // };
  static areas: string[] = [];
  /**
   * paths 其他路径地址
   */
  [pathIndex: number]: string;

  /**
   * LTD 顶级域名
   * @example com/ai/co
   */
  get 0() {
    return this.params[0];
  }
  /**
   * FLD 一级域名
   * @example 4dshoetech/revofim
   */
  get 1() {
    return this.params[1];
  }
  /**
   * env 环境域名
   * @example dev/sit/uat
   */
  get 2() {
    return this.params[2];
  }
  /**
   * cooperation 合作商域名
   * @example anta
   */
  get 3() {
    return this.params[3];
  }
  /**
   * region 区域域名
   * @example cn/us/en
   */
  get 4() {
    return this.params[4];
  }
  /**
   * modules 模块
   * 如果模块名为和主模块域名一致，则主模块域名为空
   * 如果存在子模块，则为子模块名
   * @example art/srm
   */
  get 5() {
    return this.params[5];
  }

  get length() {
    return this.params.length;
  }
  get paths() {
    return this.params.slice(6);
  }
  set paths(vPaths: string[]) {
    this.params.splice(6, 0, ...vPaths);
  }
  /** 顶级域名 */
  get tld() {
    return this[0];
  }
  /** 一级域名 */
  get fld() {
    return this[1];
  }
  /** 合作商域名 */
  get cooperation() {
    return this[3];
  }
  set cooperation(vCooperation: string) {
    this.params[3] = vCooperation;
  }
  /** 环境域名 */
  get hostEnv() {
    return this[2];
  }
  set hostEnv(vHostEnv: string) {
    this.params[2] = vHostEnv;
  }
  /** 区域域名 */
  get region() {
    // eslint-disable-next-line no-nested-ternary
    return this[3] ? this[3] : this.tld === config.alias_proxy.cn_tld ? 'cn' : this.tld === config.alias_proxy.en_tld ? 'en' : '';
  }
  /** @deprecated replaced by region */
  get area() {
    return this.region;
  }
  get env() {
    const { hostEnv2env } = this.constructor as typeof HostParams;
    return hostEnv2env[this.hostEnv];
  }
  get moduleName() {
    const { mainModule } = this.constructor as typeof HostParams;
    return this[5] || mainModule;
  }
  get submodule() {
    return this.isSubmodule(this.moduleName, this.tld, this.fld);
  }
  protected params: string[] = [];
  // eslint-disable-next-line generator-star-spacing
  *[Symbol.iterator]() {
    const { params } = this;
    for (const item of params) {
      yield item;
    }
  }
  constructor(params: string[]);
  constructor(hostOrUrl: string);
  constructor(hostOrUrlOrParsedParams: string | string[]) {
    let result: string[] = [];
    const _HostParams = this.constructor as typeof HostParams;
    const { mainModule } = _HostParams;
    if (Array.isArray(hostOrUrlOrParsedParams)) {
      result = hostOrUrlOrParsedParams;
    } else {
      const hostOrUrl = hostOrUrlOrParsedParams;
      const { modules, cooperators, hostEnv2env, areas: langs }: typeof HostParams = _HostParams;
      const moduleNames = modules;
      const locationHostEnvs = Object.keys(hostEnv2env).filter((i) => i);

      const pathArr = hostOrUrl.split('/').filter((i) => i);
      if (!pathArr.length) {
        throw new Error('');
      }
      if (/^(http|https):/.test(hostOrUrl)) {
        pathArr.shift();
      }
      const locationHostnameArr = pathArr.shift()!.split('.');
      const TLD = locationHostnameArr.pop()!;
      result = [TLD];
      // TLD
      result.push(locationHostnameArr.pop()!);

      // eslint-disable-next-line no-sparse-arrays
      const s = [locationHostEnvs, cooperators, langs];

      const minSLength = s.length;

      let LLD = locationHostnameArr.shift()!;

      let i = locationHostnameArr.length - 1;
      while (i >= 0) {
        const item = locationHostnameArr[i];
        const e = s.shift();
        if (!e) {
          result.push(item);
          i--;
          // eslint-disable-next-line no-continue
          continue;
        }
        const word = e.find((_i) => item.endsWith(_i));
        if (word) {
          result.push(word);
          i--;
        } else {
          result.push('');
        }
      }

      while (LLD) {
        const e = s.shift();
        if (!e) {
          result.push(LLD);
          break;
        }
        // eslint-disable-next-line no-loop-func
        const word = e.find((_i) => LLD.endsWith(_i));
        if (word) {
          LLD = LLD.substring(0, LLD.length - word.length);
          if (LLD.endsWith('-')) {
            LLD = LLD.substring(0, LLD.length - 1);
          }
          result.push(word);
        } else {
          result.push('');
        }
      }

      while (result.length < minSLength + 2) {
        result.push('');
      }

      if (result.length < minSLength + 3 && !moduleNames.includes(pathArr[0])) {
        result.push('');
      }

      if (pathArr.length && !result[5] && this.isSubmodule(pathArr[0], result[0], result[1])) {
        result[5] = pathArr.shift()!;
      }
      result.push(...pathArr);

      result[0] ??= '';
      result[1] ??= '';
      result[2] ??= '';
      result[3] ??= '';
      result[4] ??= '';
      result[5] ??= '';
    }

    if (result[5] === mainModule) {
      result[5] = '';
    }

    this.params = result;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  shouldJoinMainModuleName(TLD: string, FLD: string, otherDomains: string[]): boolean {
    return true;
  }
  isSubmodule(module: string, TLD: string, FLD: string): boolean {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getDomainsSeperator(TLD: string, FLD: string): string {
    return '.';
  }
  getBasePathAndPaths(): [string, string, string[]] | [string] {
    const { mainModule }: typeof HostParams = this.constructor as typeof HostParams;
    // 是否线上环境uat/art
    const otherDomains = [];
    const [TLD, FLD, hostEnv, cooperation, lang, locationHostAppName, ...paths] = this.params;
    if (hostEnv) {
      otherDomains.unshift(hostEnv);
    }
    if (cooperation) {
      otherDomains.unshift(cooperation);
    }
    if (lang) {
      otherDomains.unshift(lang);
    }
    let basePath: string;
    const isSubmodule = this.isSubmodule(locationHostAppName, TLD, FLD);
    if (!isSubmodule && locationHostAppName) {
      otherDomains.unshift(locationHostAppName);
    } else if (this.shouldJoinMainModuleName(TLD, FLD, otherDomains)) {
      otherDomains.unshift(mainModule);
    }
    const otherDomainsStr = otherDomains.join(this.getDomainsSeperator(TLD, FLD));
    const domain = [...(otherDomainsStr ? [otherDomainsStr] : []), FLD, TLD].join('.');
    if (isSubmodule) {
      basePath = `${domain}/${locationHostAppName}`;
    } else {
      basePath = domain;
    }

    const path = paths.join('/');
    if (path) {
      return [basePath, path, paths];
    }
    return [basePath];
  }
  toString() {
    const s = this.getBasePathAndPaths();
    return `${s[0]}${s[1] ? `/${s[1]}` : ''}`;
  }
  getBasePath() {
    return this.getBasePathAndPaths()[0];
  }
  getModuleHostParams<T extends HostParams>(this: T, module: string = ''): T {
    return this.clone({ 5: module });
  }
  clone<T extends HostParams>(this: T, overwrite: { [index: number]: string } = {}) {
    const _HostParams = this.constructor as Class.Class<unknown[], T>;
    const newParams = new _HostParams([...this.params]);
    Object.assign(newParams.params, overwrite);
    return newParams;
  }
  getModuleBasePath(module: string = ''): string {
    return this.getModuleHostParams(module).getBasePathAndPaths()[0];
  }
  getMainModuleBasePath() {
    return this.getModuleBasePath();
  }
}
export default HostParams;
