try {
  const tmp = __DYNAMIC_ENV__;
  if (tmp) {
    const storageEnv = JSON.parse(localStorage.getItem('ENV') ?? '{}');
    const readonlyKeys: string[] = __READONLY_DYNAMIC_ENV_KEYS__.split(',');
    const readonlyEnv = Object.fromEntries(readonlyKeys.map((i) => [i, tmp[i]]));
    const envs = { ...tmp, ...storageEnv, ...readonlyEnv };
    localStorage.setItem('ENV', JSON.stringify(envs));
    globalThis.process ??= {
      env: new Proxy(envs, {
        get(target, p) {
          if (typeof p === 'symbol') return Reflect.get(target, p);
          return Reflect.get(target, p);
        },
        set(target, p, value) {
          if (typeof p === 'symbol') return Reflect.set(target, p, value);
          if (readonlyKeys.includes(p)) return false;
          if (Reflect.get(target, p) === value) return false;
          Reflect.set(target, p, value);
          const env = JSON.parse(localStorage.getItem('ENV') || '{}');
          env[p] = value;
          localStorage.setItem('ENV', JSON.stringify(env));
          return true;
        },
      }),
    };
  }
} catch (e) {
  // console.error(e);
}
