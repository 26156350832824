import envs from '@/utils/envs';

try {
  document.domain = envs.VUE_APP_COOKIE_DOMAIN;
} catch (e) {
  console.warn('尝试设置domain失败');
  console.warn(e.message);
}

export default document.domain;
