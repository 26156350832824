export const config = {
  dataPipeDomain: 'datapipe.di-hub.net',
  alias_proxy: {
    revofim: 'di-hub',
    en_tld: 'net',
    cn_tld: 'co',
  }
  // basePath: 'di-hub.net',
  // basePathProtocol: 'http',
};

export default config;
