/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

const { location } = globalThis;

const publicPath = new URL(process.env.VUE_APP_PUBLIC_PATH || '/', location.href).href;

/** 判断url是否跨域 */
function detectCors(url: string) {
  const a = new URL(url, location.href);
  return a.origin !== location.origin;
}

const { Worker } = globalThis;
/**
 * 这个就是Worker，但是可以跨域
 * 实现了Worker的所有接口
 * @see https://developer.mozilla.org/zh-CN/docs/Web/API/Worker
 * @see https://github.com/webpack/webpack/discussions/14648
 */
export class CorsWorker implements Worker {
  #worker: Worker;
  constructor(scriptURL: string | URL, options?: WorkerOptions | undefined) {
    let url = typeof scriptURL === 'string' ? scriptURL.toString() : scriptURL.href;
    if (url.startsWith(publicPath)) {
      url = url.replace(publicPath, '');
    }
    if (detectCors(url)) {
      let scripts: string;
      if (options?.type === 'module') {
        scripts = `import ${JSON.stringify(url)};`;
      } else {
        scripts = `importScripts(${JSON.stringify(url)});`;
      }
      url = URL.createObjectURL(
        new Blob([scripts], {
          type: 'application/javascript',
        })
      );
    }
    this.#worker = new Worker(url, options);
  }
  get onmessage(): ((this: Worker, ev: MessageEvent<unknown>) => unknown) | null {
    return this.#worker.onmessage;
  }
  set onmessage(value: ((this: Worker, ev: MessageEvent<unknown>) => unknown) | null) {
    this.#worker.onmessage = value;
  }
  get onmessageerror(): ((this: Worker, ev: MessageEvent<unknown>) => unknown) | null {
    return this.#worker.onmessageerror;
  }
  set onmessageerror(value: ((this: Worker, ev: MessageEvent<unknown>) => unknown) | null) {
    this.#worker.onmessageerror = value;
  }
  get onerror(): ((this: AbstractWorker, ev: ErrorEvent) => any) | null {
    return this.#worker.onerror;
  }
  set onerror(value: ((this: AbstractWorker, ev: ErrorEvent) => any) | null) {
    this.#worker.onerror = value;
  }
  postMessage(message: any, transfer: Transferable[]): void;
  postMessage(message: any, options?: StructuredSerializeOptions): void;
  postMessage(message: any, transferOroptions?: StructuredSerializeOptions | Transferable[]): void {
    if (Array.isArray(transferOroptions)) {
      this.#worker.postMessage(message, transferOroptions);
    } else {
      this.#worker.postMessage(message, transferOroptions);
    }
  }
  terminate(): void {
    this.#worker.terminate();
  }
  addEventListener<K extends keyof WorkerEventMap>(
    type: K,
    listener: (this: Worker, ev: WorkerEventMap[K]) => unknown,
    options?: boolean | AddEventListenerOptions | undefined
  ): void {
    this.#worker.addEventListener(type, listener, options);
  }
  removeEventListener<K extends keyof WorkerEventMap>(
    type: K,
    listener: (this: Worker, ev: WorkerEventMap[K]) => unknown,
    options?: boolean | EventListenerOptions | undefined
  ): void {
    this.#worker.removeEventListener(type, listener, options);
  }
  dispatchEvent(event: Event): boolean {
    return this.#worker.dispatchEvent(event);
  }
}

export default CorsWorker;
